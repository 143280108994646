import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getDate } from "../../utils/date";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { apiRequest } from "../../utils/api";

function Coupons() {
  const [name, setName] = useState("");
  const [percentage, setPercentage] = useState("");
  const [validTill, setValidTill] = useState(new Date());
  const [total, setTotal] = useState("");
  const [coupons, setCoupons] = useState([]);

  async function submit(e) {
    e.preventDefault();

    try {
      if (!name || !percentage)
        return Alert("error", "Warning", "Please Fill Name And Percentage");
      let payload = {
        coupon: name,
        percentage,
        valid: new Date(validTill).getTime(),
        total_usage: total,
      };
      let res = await apiRequest.post(`/coupon/create`, payload);
      if (res.data.status === 200) {
        window.location.reload();
      } else {
        Alert("error", "Warning", res.data.message);
      }
    } catch (error) {
      Alert("error", "Error", error.message);
    }
  }

  useEffect(() => {
    getCoupons();
    document.title = `Coupons | JDM Plates`;
  }, []);

  async function getCoupons() {
    try {
      let res = await apiRequest.get(`/coupon/all`);
      if (res.data.status === 200) {
        setCoupons(res.data.message);
      } else {
        setCoupons([]);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Coupon",
      selector: (row) => (
        <>
          <p>{row.c_name}</p>
        </>
      ),
    },
    {
      name: "Percentage Off",
      selector: (row) => (
        <>
          <p>{row.c_percentage}%</p>
        </>
      ),
    },

    {
      name: "Added Date",
      selector: (row) => (
        <>
          {" "}
          <p>{getDate(row.created_at)}</p>{" "}
        </>
      ),
    },
    {
      name: "Valid Till",
      selector: (row) => (
        <p>{getDate(new Date(parseInt(row.valid_till)).toDateString())}</p>
      ),
    },
    {
      name: "Total Coupons",
      selector: (row) => (
        <>
          <p>{row.total_usage}</p>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="main-content">
        <div className="row">
          <div className="table-title coupons-heading">
            <h2>Coupons</h2>
            <button
              //   onClick={handleButtonClick}
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              className="btn coupon-btn"
            >
              Add Coupon
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="datatable0">
              <DataTable columns={columns} data={coupons} pagination />
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Coupon
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name">Name</label>
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="name"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="percentage">Percentage</label>
                <input
                  id="percentage"
                  onChange={(e) => setPercentage(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="valid">Valid Till</label>
                <div className="date-input">
                  <DatePicker
                    className="form-control"
                    selected={validTill}
                    id="valid"
                    minDate={new Date()}
                    onChange={(date) => setValidTill(date)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="totals">Total Number Of Coupons</label>
                <input
                  id="totals"
                  onChange={(e) => setTotal(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={submit}
                className="btn btn-primary"
              >
                Save changes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Alert(icon, title, text) {
  return Swal.fire({
    icon,
    title,
    text,
  });
}

export default Coupons;
