import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { apiRequest } from "../../utils/api";
import { Link } from "react-router-dom";
import { getDate } from "../../utils/date";
function Customers() {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getCustomers();
    document.title = `Customers | JDM Plates`;
  }, []);
  async function getCustomers() {
    try {
      let res = await apiRequest.get(`/customer/all`);
      if (res.data.status === 200) {
        setCustomers(res.data.message);
      } else {
        setCustomers([]);
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Reg Number",
      selector: (row) => (
        <>
          {row.order_fullnumber ? (
            <button type="button" className="btn btn-warning yello-number">
              {row.order_fullnumber}
            </button>
          ) : (
            <p>No Plate</p>
          )}
        </>
      ),
    },
    {
      name: "Name",
      selector: (row) => (
        <>
          <p>
            {row.firstname} {row.surname}
          </p>
        </>
      ),
    },

    {
      name: "Order Date",
      selector: (row) => (
        <>
          {" "}
          <p>{getDate(row.registered_on)}</p>{" "}
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {row.order_status ? (
            <p>
              {row.order_status}
            </p>
          ) : (
            <p className="approved-link">
              No Order
            </p>
          )}
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {" "}
          <div className="edit-delet">
            {row.Action}
            {/* <Link>
                <img src="img/trash.png" />
              </Link> */}
            <Link to={`/customer/${row.uid}`}>
              <img src="/img/eye.png" />{" "}
            </Link>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="main-content">
      <div className="row">
        <div className="table-title">
          <h2>Customers</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="datatable0">
            <DataTable columns={columns} data={customers} pagination />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;
