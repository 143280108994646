import React, { useEffect, useState } from "react";
import { apiRequest } from "../utils/api";
import { getDateTime } from "../utils/date";
import { useNavigate } from "react-router-dom";
import Cron from "./Cron";

import DataTable from "react-data-table-component";

function CronTable() {
  const [cronMsg, setCronMsg] = useState([]);

  const navigate = useNavigate();

  function sortDescendingByRunOn(arr) {
    return arr.sort((a, b) => new Date(b.run_on) - new Date(a.run_on));
  }
  useEffect(() => {
    getCronDetails();
  }, []);

  async function getCronDetails() {
    try {
      let res = await apiRequest.get("/cron/all");
      if (res.data.status === 200) {
        setCronMsg(sortDescendingByRunOn(res.data.message));
      } else {
        setCronMsg([]);
        alert(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const columns = [
    {
      name: "#",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Start Date",
      selector: (row) => <>{getDateTime(row?.start_date)}</>,
    },
    {
      name: "End Date",
      selector: (row) => <>{getDateTime(row?.end_date)}</>,
    },
    {
      name: "New Added",
      selector: (row) => <>{parseFloat(row?.total_available)}</>,
    },
    {
      name: "Removed",
      selector: (row) => <>{parseFloat(row?.total_deleted)}</>,
    },
    {
      name: "Run Date",
      selector: (row) => <>{getDateTime(row?.run_on)}</>,
    },
  ];

  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title coupons-heading">
            <h2>Cron</h2>
          </div>
        </div>
        <Cron />
        <div className="dashboard-tabel">
          <div className="row">
            <div className="col-md-12">
              <div className="number-table table-responsive">
                <DataTable columns={columns} data={cronMsg} pagination />
                {/* <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">New Plates</th>
                      <th scope="col">Removed Plates</th>
                      <th scope="col">Running Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cronMsg?.map((cron, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{getDateTime(cron?.start_date)}</td>
                        <td>{getDateTime(cron?.end_date)}</td>
                        <td>{parseFloat(cron?.total_available)}</td>
                        <td>{parseFloat(cron?.total_deleted)}</td>
                        <td>{getDateTime(cron?.run_on)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add modal */}
    </div>
  );
}

export default CronTable;
