import React, { useEffect, useState } from "react";
import { apiRequest } from "../utils/api";
import { checkCron } from "../utils/cron";
import Swal from "sweetalert2";
import moment from "moment";

function Cron() {
  const [inputDateTime1, setInputDateTime1] = useState("");
  const [inputDateTime2, setInputDateTime2] = useState("");
  const [minute, setMinute] = useState("*");
  const [hours, setHours] = useState("*");
  const [dayMonth, setDayMonth] = useState("*");
  const [month, setMonth] = useState("*");
  const [day, setDay] = useState("*");
  const [error, setError] = useState("");
  const [cronMsg, setCronMsg] = useState();

  useEffect(() => {
    setCronMsg(
      checkCron(
        `${minute ? minute : "*"}  ${hours ? hours : "*"} ${
          dayMonth ? dayMonth : "*"
        } ${month ? month : "*"} ${day ? day : "*"}`
      ).description
    );
  }, [minute, hours, dayMonth, month, day]);

  // get cron details
  useEffect(() => {
    getCronDetails();
  }, []);
  async function getCronDetails() {
    try {
      let res = await apiRequest.get("/cron/details");
      if (res.data.status === 200) {
        const { message } = res.data;
        setMinute(message?.minute);
        setHours(message?.hour);
        setDayMonth(message?.day_month);
        setMonth(message?.month);
        setDay(message?.day_week);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // update cron details
  async function updateCron(e) {
    e.preventDefault();
    try {
      const payload = {
        minute,
        hour: hours,
        day_month: dayMonth,
        month,
        day_week: day,
      };
      let res = await apiRequest.put(`/cron/auto-timing`, payload);
      if (res.data?.status == 200) {
        alert("Updated");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      console.log(error?.message);
    }
  }

  // Set a specific end date (e.g., one week from now)
  const endDate = new Date();
  endDate.setDate(endDate.getDate()); // Set to one week from today

  const handleChange1 = (e) => {
    const value = e.target.value;
    setInputDateTime1(value);
    validateDate(value, "first");
  };

  const handleChange2 = (e) => {
    const value = e.target.value;
    setInputDateTime2(value);
    validateDate(value, "second");
  };

  const validateDate = (value, inputNumber) => {
    const inputDate = new Date(value);
    if (inputDate > endDate) {
      setError(
        `The selected date for ${inputNumber} must be before the end date.`
      );
    } else {
      setError("");
    }
  };

  // hit manual cron

  const handleSubmit = async () => {
    Swal.fire({
      title: "Are you sure run cron?",
      text: "You won't be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await apiRequest.post("/cron/manual");

        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Please Wait For Few Moments",
          showConfirmButton: false,
          timer: 1500,
        });
        if (res.data.status == 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Data Has Been Saved",
            showConfirmButton: false,
            timer: 1500,
          });
          window?.location.reload();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      }
    });
    // const isConfirm = confirm("Are You Want To Sure Run Cron?");
    // if (isConfirm) {
    //   let res = await apiRequest.post("/cron/manual");

    //   if (res.status == 200) {
    //     alert("Manual Data Update/Delete Started");
    //   }
    // }
  };
  return (
    <div>
      <div className="main-content">
        {/* <div className="row d-none">
          <div className="table-title coupons-heading ">
            <h2>Run Cron</h2>
          </div>
          <form className="cron-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Starting Date and Time:
                    <input
                      className="form-control"
                      type="datetime-local"
                      value={inputDateTime1}
                      onChange={(e) => setInputDateTime1(e.target.value)}
                      required
                      max={endDate.toISOString().slice(0, 16)} // Prevent selection beyond the end date
                    />
                  </label>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Ending Date and Time:
                    <input
                      className="form-control"
                      type="datetime-local"
                      value={inputDateTime2}
                      onChange={handleChange2}
                      required
                      max={endDate.toISOString().slice(0, 16)} // Prevent selection beyond the end date
                    />
                  </label>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <button className="btn coupon-btn" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="row d-none">
          <div className="table-title coupons-heading ">
            <h2>Automation Cron Timing</h2>
          </div>
          <div className="mb-3">
            *
            <a href="https://crontab.guru/" target="_blank">
              Click Here To Check Your Cron Timing
            </a>{" "}
            Then Please Update Below Details.
          </div>
          <h3 style={{ textTransform: "capitalize" }}>{cronMsg}</h3>
          <form className="cron-form" onSubmit={updateCron}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Minute:
                    <input
                      className="form-control"
                      type="text"
                      value={minute}
                      onChange={(e) => setMinute(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Hours:
                    <input
                      className="form-control"
                      type="text"
                      value={hours}
                      onChange={(e) => setHours(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Day(month):
                    <input
                      className="form-control"
                      type="text"
                      value={dayMonth}
                      onChange={(e) => setDayMonth(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Month:
                    <input
                      className="form-control"
                      type="text"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Day(week):
                    <input
                      className="form-control"
                      type="text"
                      value={day}
                      onChange={(e) => setDay(e.target.value)}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <button className="btn coupon-btn" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div> */}
        <div className="row">
          <div className="table-title coupons-heading ">
            <button className="btn coupon-btn" onClick={handleSubmit}>
              Run Cron Mannualy
            </button>
          </div>
          <p>* When You Click On Above Button It Will Run Cron Job</p>
        </div>
      </div>
    </div>
  );
}

export default Cron;
