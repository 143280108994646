import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { apiRequest } from "../utils/api";
import { Link } from "react-router-dom";
import { getDate } from "../utils/date";

function Premium() {
  const [plates, setPlates] = useState([]);
  const [selectedPlates, setSelectedPlates] = useState([]);
  const [applyStatus, setApplyStatus] = useState("");
  const [search, setSearch] = useState("");
  useEffect(() => {
    getPlates();
    document.title = `Premium Plates | JDM Plates`;
  }, []);

  async function getPlates() {
    try {
      let res = await apiRequest.get(`/premium/seller/all`);
      if (res.data.status === 200) {
        setPlates(res.data.message);
        setSelectedPlates(res.data.message);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Reg Number",
      selector: (row) => (
        <>
          <button type="button" className="btn btn-warning yello-number">
            {row.sell_number}
          </button>
        </>
      ),
    },
    {
      name: "Total Offers",
      selector: (row) => (
        <>
          <p>{row.totalOffer}</p>
        </>
      ),
    },

    {
      name: "Listing Date",
      selector: (row) => (
        <>
          {" "}
          <p>{getDate(row.registered_on)}</p>{" "}
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          {" "}
          <button type="button" className="btn btn-link approved-link">
            {row.sell_number_status}
          </button>
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          {" "}
          <div className="edit-delet">
            {row.Action}
            {/* <Link>
              <img src="img/trash.png" />
            </Link> */}
            <Link to={`/premiumlisting/${row.sell_number}`}>
              <img src="/img/eye.png" />{" "}
            </Link>
            <Link onClick={() => deleteNumber(row.sell_id)}>
              <img src="/img/trash.png" />{" "}
            </Link>
          </div>
        </>
      ),
    },
  ];

  async function deleteNumber(id) {
    try {
      let res = await apiRequest.delete(`/premium/seller/delete-number/${id}`);
      console.log(res);
      if (res.data.status === 200) {
        setPlates(plates.filter((p) => p.sell_id != id));
        setSelectedPlates(plates.filter((p) => p.sell_id != id));
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function openCustomerPage(email) {
    console.log(email);
  }
  async function openPlatePage(id) {
    console.log(id);
  }

  function applyFilter() {
    let value = applyStatus;

    if (!value || value == "all") {
      setSelectedPlates(plates);
    }
    if (value == "pending") {
      setSelectedPlates(
        plates.filter((p) => p?.sell_number_status?.toLowerCase() == "pending")
      );
    }
    if (value == "APPROVED") {
      setSelectedPlates(
        plates.filter((p) => p?.sell_number_status?.toLowerCase() == "approved")
      );
    }
    if (value == "SOLD") {
      setSelectedPlates(
        plates.filter((p) => p?.sell_number_status?.toLowerCase() == "sold")
      );
    }
    if (value == "REJECTED") {
      setSelectedPlates(
        plates.filter((p) => p?.sell_number_status?.toLowerCase() == "REJECTED")
      );
    }
  }

  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title selet">
            <h2>Premium Plates Listing</h2>
            <div className="search-list">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => setApplyStatus(e.target.value)}
              >
                <option selected="" value={"all"}>
                  All Records
                </option>
                <option value={"pending"}>Pending</option>
                <option value={"APPROVED"}>Approved</option>
                <option value={"SOLD"}>Sold</option>
                <option value={"REJECTED"}>Rejected</option>
              </select>
              <button
                type="button"
                onClick={applyFilter}
                className="btn btn-primary"
              >
                Apply
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="Enter Number For Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="datatable0">
              <DataTable
                columns={columns}
                data={
                  search.length > 0
                    ? selectedPlates.filter(
                        (p) =>
                          p?.sell_number
                            ?.toLowerCase()
                            ?.indexOf(search.toLowerCase()) >= 0
                      )
                    : selectedPlates
                }
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Premium;
