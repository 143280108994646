import React from "react";

function Card({ image, title, value }) {
  return (
    <>
      <div className="col-md-3">
        <div className="dashboard-card">
          <div className="dashboard-card-left">
            <h3>{title}</h3>
            <h4>{value ? value : 0}</h4>
          </div>
          <div className="dashboard-card-img">
            <span>
              <img src={`/img/${image}.png`} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Card;
