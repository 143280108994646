import React, { useEffect, useState } from "react";
import { apiRequest } from "../utils/api";

function Commission() {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [commission, setCommission] = useState(0);
  const [title, setTitle] = useState("");
  const [commissions, setCommissions] = useState([]);
  const [cid, setCid] = useState(null);

  async function addCommission() {
    try {
      let payload = { max_price: max, min_price: min, title, commission };
      let res = await apiRequest.post("/commission/add", payload);
      if (res.data.status === 200) {
        window.location.reload();
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    getCommissions();
  }, []);

  async function getCommissions() {
    try {
      let res = await apiRequest.get("/commission/all");
      if (res.data.status === 200) {
        setCommissions(res.data.message);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  function openModal(id) {
    setCid(id);
  }
  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title coupons-heading">
            <h2>Commission</h2>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              className="btn coupon-btn"
            >
              Add Commission
            </button>
          </div>
        </div>

        {/* <div className="dashboard-tabel">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard-tabel">
                <h2>Recent Orders</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="datatable0">
                <DataTable columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div> */}
        <div className="dashboard-tabel">
          <div className="row">
            <div className="col-md-12">
              <div className="number-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Commission</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {commissions?.map((c, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{c.title}</td>
                        <td>{c.commission}%</td>

                        <td>
                          <div classname="edit-delet">
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#editModal"
                              onClick={() => openModal(c.c_id)}
                            >
                              <img src="/img/eye.png" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Add Commission
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  id="name"
                  onChange={(e) => setTitle(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="totals">Min Price</label>
                <input
                  id="totals"
                  onChange={(e) => setMin(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="percentage">Max Price</label>
                <input
                  id="percentage"
                  onChange={(e) => setMax(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="totals">Commission (%) </label>
                <input
                  id="totals"
                  onChange={(e) => setCommission(e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={addCommission}
                className="btn btn-primary"
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <EditModal id={cid} />
    </div>
  );
}

function EditModal({ id }) {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [commission, setCommission] = useState(0);
  const [title, setTitle] = useState("");

  useEffect(() => {
    getCommission();
  }, [id]);

  async function getCommission() {
    try {
      let res = await apiRequest.get("/commission/single/" + id);
      if (res.data.status === 200) {
        const { min_price, max_price, commission, title } = res.data.message;
        setMin(min_price);
        setMax(max_price);
        setTitle(title);
        setCommission(commission);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  async function updateCommission() {
    try {
      let payload = { max_price: max, min_price: min, title, commission, id };
      let res = await apiRequest.put("/commission/update", payload);
      if (res.data.status === 200) {
        window.location.reload();
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="editModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Commission
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  id="name"
                  value={title}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="totals">Min Price</label>
                <input
                  id="totals"
                  onChange={(e) => setMin(e.target.value)}
                  type="text"
                  value={min}
                  className="form-control"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="percentage">Max Price</label>
                <input
                  id="percentage"
                  onChange={(e) => setMax(e.target.value)}
                  type="text"
                  value={max}
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="totals">Commission (%) </label>
                <input
                  id="totals"
                  onChange={(e) => setCommission(e.target.value)}
                  type="text"
                  value={commission}
                  className="form-control"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={updateCommission}
                className="btn btn-primary"
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Commission;
