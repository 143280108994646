import React from "react";
import { getDate } from "../../../utils/date";

function Table({ plates }) {
  console.log(plates);
  return (
    <div>
      <div className="number-table table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Number</th>
              <th scope="col">Price</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {plates?.length > 0
              ? plates?.map((p, i) => (
                  <tr key={i + 1}>
                    <td>{i + 1}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-warning yello-number"
                      >
                        {p.sell_number}
                      </button>
                    </td>
                    <td>£{p.sell_price}</td>
                    <td>{getDate(p.registered_on)}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
