import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { apiRequest } from "../utils/api";
import { addStorage, getStorageValue } from "../utils/storage";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  async function login(e) {
    e.preventDefault();
    try {
      let res = await apiRequest.post(`/admin-user/login`, { email, password });
      console.log(res);
      if (res.data.status === 200) {
        addStorage("token", res.data.message);
        setErrorMsg("");
        navigate("/");
      } else {
        setErrorMsg(res.data.message);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(error.message);
    }
  }

  useEffect(() => {
    document.title = `Login | JDM Plates`;
    async function matchToken() {
      try {
        let res = await apiRequest.post(`/admin-user/match-token`, {
          token: getStorageValue("token"),
        });
        if (res.data.status === 200) {
          addStorage("email", res.data.message.email);
          addStorage("user_id", res.data.message.user_id);
          setTimeout(() => {
            navigate("/");
          }, 400);
        } else {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      }
    }
    matchToken();
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <div className="login-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-logo">
                <img src="/img/logo.svg" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="login-form">
                <form onSubmit={login}>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Password:</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="eyes-icon"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {/* <div className="form-group">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div> */}
                  {errorMsg ? <p className="text-danger">{errorMsg}</p> : null}
                  <div className="login-button">
                    {/* <Link to="/premium"> */}
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
