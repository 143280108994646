function addStorage(key, value) {
  localStorage.setItem(key, value);
}

function getStorageValue(key) {
  return localStorage.getItem(key);
}

function removeStorage() {
  localStorage.clear();
}
export { addStorage, getStorageValue, removeStorage };
