import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { apiRequest } from "../utils/api";
import Enquiry from "./Enquiry";

function PremiumListing() {
  const statuses = ["pending", "approved", "sold", "rejected"];
  const [plateStatus, setPlateStatus] = useState("");
  const [category, setCategory] = useState("");
  const [adminPrice, setAdminPrice] = useState(0);
  // const [ageIdentifier, setAgeIdentifier] = useState("");
  const [plate, setPlate] = useState();
  const [numberPlate, setNumberPlate] = useState();
  const [offers, setOffers] = useState([]);
  const [adminFixPrice, setAdminFixPrice] = useState(50);
  const [ageIdentifier, setAgeIdentifier] = useState("");
  const [commission, setCommission] = useState("");
  const { number } = useParams();
  let fullNumber = number.toUpperCase();

  useEffect(() => {
    getPlate();
  }, []);

  async function getPlate() {
    try {
      let res = await apiRequest.get(`/premium/seller/single/${fullNumber}`);
      if (res.data.status === 200) {
        let { premium: message, offers } = res.data.message;
        setCategory(message.sell_category);
        setAdminPrice(message.admin_price);
        setPlateStatus(message?.sell_number_status);
        setPlate(message);
        setNumberPlate(message.sell_number);
        setCommission(message.admin_commission);
        setOffers(offers);
        setAdminFixPrice(parseFloat(message.admin_price));
        document.title = `${message?.sell_number} | JDM Plates`;
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function updatePlate(e) {
    e.preventDefault();
    try {
      if (category === "new-style" && !ageIdentifier) {
        return alert("Please Enter Age");
      }
      let res = await apiRequest.put(`/premium/seller/update-price`, {
        number: number,
        category: category,
        status: plateStatus,
        adminPrice: adminPrice,
        age: ageIdentifier,
        numberPlate,
        commission: plateStatus === "REJECTED" ? 0 : commission,
        sell_price: plate.sell_price,
        plate,
      });
      if (res.data.status === 200) {
        alert("updated");
      } else {
        console.log("not", res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  console.log(adminPrice);

  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title">
            <h2>Update Listing Status</h2>
          </div>
        </div>
        {plate && (
          <div className="update-form">
            <div className="row">
              <div className="col-md-6">
                <div className="plate-form">
                  <form onSubmit={updatePlate}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Listing Status<sup>*</sup>
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setPlateStatus(e.target.value)}
                          >
                            {statuses.map((s) => (
                              // waiting for approval
                              <option
                                selected={plateStatus.toLowerCase() === s}
                              >
                                {s === "pending"
                                  ? "Waiting for approval"
                                  : s.toUpperCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="name">
                            Name <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={numberPlate}
                            onChange={(e) => setNumberPlate(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Plate Category<sup>*</sup>
                          </label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option
                              selected={category == "new-style"}
                              value={"new-style"}
                            >
                              New Style
                            </option>
                            <option
                              selected={category == "prefix"}
                              value={"prefix"}
                            >
                              Prefix
                            </option>
                            <option
                              selected={
                                category == "dateless" || category == "other"
                              }
                              value={"dateless"}
                            >
                              Dateless
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {category === "new-style" ? (
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Age Identifier<sup>*</sup>
                        </label>
                        <input
                          placeholder="Age Identifier"
                          className="form-control"
                          onChange={(e) => setAgeIdentifier(e.target.value)}
                        />
                      </div>
                    ) : null}
                    {/* <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Age Identifier*<sup>*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputText"
                        placeholder="63"
                      />
                    </div>
                  </div>
                </div> */}

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="basic-url" className="form-label">
                            Admin Commission<sup>*</sup>
                          </label>

                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="0"
                              value={commission}
                              aria-label="Username"
                              aria-describedby="basic-addo11"
                              onChange={(e) => setCommission(e.target.value)}
                            />
                            <span
                              className="input-group-text"
                              id="basic-addo11"
                            >
                              %
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="basic-url" className="form-label">
                            Admin Price<sup>*</sup>
                          </label>

                          <div className="input-group mb-3">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              £
                            </span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="688"
                              value={adminPrice}
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              onChange={(e) => setAdminPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">
                          Change Status
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-6">
                <div className="listing-name">
                  <ul>
                    <li>
                      <h3>
                        <span>Name:</span>
                        {plate.seller_name}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Email:</span>
                        <label style={{ textTransform: "lowercase" }}>
                          {plate.seller_email}
                        </label>
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Phone:</span>
                        {plate.seller_phone}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Street Address:</span>
                        {plate.seller_address}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Town:</span>
                        {plate.seller_town}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>County:</span>
                        {plate.seller_country}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Postcode:</span>
                        {plate.seller_post_code}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Requested Price:</span>£{plate.sell_price}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>I own this number plate:</span>
                        {plate?.seller_name?.toLowerCase() ==
                        plate?.sell_owner?.toLowerCase()
                          ? "Yes"
                          : "No"}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>VAT registered:</span>
                        {plate.seller_vat_register != "0" ? "Yes" : "No"}
                      </h3>
                    </li>
                    <li>
                      <h3>
                        <span>Retention Certificate:</span>
                        {plate.sell_retention_certificate != "0" ? "Yes" : "No"}
                      </h3>
                    </li>
                  </ul>
                  <h2>
                    Registration Number:<span>{plate.sell_number}</span>
                  </h2>
                </div>
              </div>
            </div>
            {offers.length > 0 && (
              <div className="row">
                <Enquiry offers={offers} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PremiumListing;
