import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { getDate } from "../utils/date";
import { apiRequest } from "../utils/api";

function Enquiry({ offers }) {
  const [modalData, setModalData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Reg Number",
      selector: (row) => (
        <>
          <button type="button" className="btn btn-warning yello-number">
            {row.bidding_fullNumber}
          </button>
        </>
      ),
    },
    {
      name: "Customer",
      selector: (row) => (
        <>
          <div className="table-view">
            {row.bidder_first_name} {row.bidder_last_name}
          </div>
        </>
      ),
    },

    {
      name: "Offered Price",
      selector: (row) => (
        <>
          {" "}
          <p>£{row.bidder_price}</p>{" "}
        </>
      ),
    },
    {
      name: "Posted Date",
      selector: (row) => (
        <>
          <p>{getDate(row.bidding_date)} </p>
        </>
      ),
    },
    {
      name: "View",
      selector: (row) => (
        <>
          {" "}
          <div className="edit-delet">
            {row.Action}
            <button
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => openModal(row.bidding_id)}
            >
              <img src="/img/eye.png" />
            </button>
          </div>
        </>
      ),
    },
  ];

  async function openModal(id) {
    try {
      let res = await apiRequest(`/offer/single/${id}`);
      if (res.data.status === 200) {
        console.log(res.data);
        setModalData(res.data.message);
        setIsModalOpen(true);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <div className="main-content">
        <div className="row">
          <div className="table-title">
            <h2>All Enquiries & Offers</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="datatable0">
              <DataTable columns={columns} data={offers} pagination />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? <Modal data={modalData} /> : null}
    </div>
  );
}

function Modal({ data }) {
  let offer = data;
  console.log(offer);
  return (
    <>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Offer
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="listing-name">
                    <ul>
                      <li>
                        <h3>
                          <span>Name:</span>
                          {offer.bidder_title}. {offer.bidder_first_name}{" "}
                          {offer.bidder_last_name}
                        </h3>
                      </li>
                      <li>
                        <h3>
                          <span>Email:</span>
                          <label style={{ textTransform: "lowercase" }}>
                            {offer.bidder_email}
                          </label>
                        </h3>
                      </li>
                      <li>
                        <h3>
                          <span>Phone:</span>
                          {offer.bidder_phone}
                        </h3>
                      </li>
                      <li>
                        <h3>
                          <span>Price:</span>£{offer.bidder_price}
                        </h3>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Enquiry;
