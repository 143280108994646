import React, { useEffect, useState } from "react";
import { apiRequest } from "../../utils/api";
import DataTable from "react-data-table-component";

const sections = [
  {
    name: "Old Styles",
    id: 1,
    url: "/old/date-less/single",
    deleteUrl: "/old/date-less/delete",
  },
  {
    name: "New Styles",
    id: 2,
    url: "/plate/new-style/single",
    deleteUrl: "/plate/new-style/single/delete",
  },
  {
    name: "Prefix Styles",
    id: 3,
    url: "/prefix/prefix-style/single",
    deleteUrl: "/prefix/prefix-style/single/delete",
  },
];
function DeleteNumber() {
  const [selected, setSelected] = useState({
    name: "Old Styles",
    id: 1,
    url: "/date-less/single",
    deleteUrl: "/old/date-less/delete",
  });
  const [search, setSearch] = useState();
  const [numbers, setNumbers] = useState([]);

  async function searchNumber() {
    try {
      const url = `${selected?.url}/${search?.toUpperCase().replace(" ", "")}`;

      const res = await apiRequest.get(url);
      if (res.data.status == 200) {
        let { message } = res.data;
        setNumbers([message]);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteNumber(number) {
    try {
      const url = `${selected?.deleteUrl}/${number}`;
      const res = await apiRequest.delete(url);
      if (res.data.status == 200) {
        setNumbers([]);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(selected);
  }, [selected]);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => <>{index + 1}</>,
    },
    {
      name: "Full Number",
      selector: (row) => (
        <>
          <p>{row?.fullNumber}</p>
        </>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <button onClick={() => deleteNumber(row?.fullNumber)} className="btn">
            <img src="/img/trash.png" />
          </button>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <div className="main-content">
          <h2>Search Number</h2>
          <div className="row">
            <div className="col-md-12 mb-5">
              {sections?.map((s, index) => (
                <button
                  className={`btn  me-3 ${
                    selected?.id == s?.id ? "btn-success" : "btn-dark"
                  }`}
                  onClick={(e) => setSelected(s)}
                >
                  {s.name}
                </button>
              ))}
            </div>
            <div className="col-md-12 mb-5">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Number"
                  aria-label="Enter Number"
                  aria-describedby="basic-addon2"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span
                  onClick={searchNumber}
                  className="input-group-text"
                  style={{ cursor: "pointer" }}
                  id="basic-addon2"
                >
                  Search
                </span>
              </div>
            </div>
            <div className="col-md-12 mb-5">
              <DataTable columns={columns} data={numbers} pagination />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteNumber;
