function checkCron(crontab) {
  const parts = crontab.trim().split(/\s+/);

  if (parts.length !== 5) {
    throw new Error(
      "Invalid crontab format. Expected format: 'minute hour day month weekday'"
    );
  }

  const [minute, hour, day, month, weekday] = parts;

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let description = "";

  // Minute description
  if (minute.startsWith("*/")) {
    const interval = minute.split("/")[1];
    description += `runs every ${interval} minutes`;
  } else if (minute === "*") {
    description += `runs every minute`;
  } else {
    description += `at ${minute} minutes past`;
  }

  // Hour description
  if (hour !== "*") {
    description += ` ${hour} AM`;
  }

  // Weekday description
  if (weekday !== "*") {
    description = `on every ${weekdays[Number(weekday)]}, ` + description;
  } else {
    description = `every day, ` + description;
  }

  return {
    minute,
    hour,
    day,
    month,
    weekday,
    description: description.trim(),
  };
}

export { checkCron };
